import { GET_MASTER } from "../constants";

const INIT_STATE = {
  master: {}
};

const MasterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MASTER:
      return {
        ...state,
        master: action.value
      };
    default:
      return state;
  }
};

export default MasterReducer;
