import {
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILED,
  GET_OTP_REQUEST,
  GET_OTP_SUCCESS,
  GET_OTP_FAILED,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  LOGOUT_USER,
  GET_ADDRESS_SUCCESS,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILED,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILED,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
} from "../constants";

const INIT_STATE = {
  logged_in: false,
  isLoading: false,
  is_new_user: false,
  user: {},
  address: [],
};

const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case GET_OTP_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        is_new_user: action.value,
        isLoading: false,
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.value,
        logged_in: true,
        isLoading: false,
      };
    case GET_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        address: action.value,
      };
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        address: [...state.address, action.value],
      };
    case ADD_ADDRESS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        address: state.address.map((addr) =>
          addr.id === action.value.id ? action.value : addr
        ),
      };
    case UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        address: state.address.filter((addr) => addr.id !== action.payload),
      };
    case DELETE_ADDRESS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT_USER:
      return {
        logged_in: false,
        user: {},
        is_new_user: false,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
