// Layout and colors CONSTANTS
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const BLUE_THEME = "BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";
export const GET_MASTER = "GET_MASTER";
export const CREATE_PROFILE_REQUEST = "CREATE_PROFILE_REQUEST";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_FAILED = "CREATE_PROFILE_SUCCESS";
export const GET_OTP_REQUEST = "GET_OTP_REQUEST";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILED = "GET_OTP_FAILED";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILED = "GET_CART_FAILED";
export const GET_RECOMMENDATION_PRODUCT_REQUEST =
  "GET_RECOMMENDATION_PRODUCT_REQUEST";
export const GET_RECOMMENDATION_PRODUCT_SUCCESS =
  "GET_RECOMMENDATION_PRODUCT_SUCCESS";
export const GET_RECOMMENDATION_PRODUCT_FAILED =
  "GET_RECOMMENDATION_PRODUCT_FAILED";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";
export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILED = "UPDATE_CART_FAILED";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";
export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";
