import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import ProfileReducer from './profile/ProfileReducer';
import MasterReducer from './master/MasterReducer';
import CartReducer from './cart/CartReducer';
import RecommendationReducer from './recommendation/RecommendationReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  ProfileReducer,
  MasterReducer,
  CartReducer,
  RecommendationReducer
});

export default RootReducers;
