import {
  GET_CART_FAILED,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILED,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
} from "../constants";

const INIT_STATE = {
  isLoading: false,
  cart: {},
};

const CartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.value,
      };
    case GET_CART_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CART_REQUEST:
      return {
        ...state,
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.value,
      };
    case UPDATE_CART_FAILED:
      return {
        ...state,
      };
    case DECREASE_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          products: state.cart.products.map((product) =>
            product.product_id === action.payload
              ? { ...product, quantity: Math.max(product.quantity - 1, 0) }
              : product
          ),
        },
      };
    case INCREASE_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          products: state.cart.products.map((product) =>
            product.product_id === action.payload
              ? { ...product, quantity: product.quantity + 1 }
              : product
          ),
        },
      };
    case PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PAYMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default CartReducer;
