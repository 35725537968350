import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import img1 from "../../assets/images/backgrounds/home_bg.jpg";
import LogoIcon from "../../layouts/full-layout/logo/LogoIcon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function CancellationRefundPolicy() {
    const navigate = useNavigate();
    return (
        <PageContainer
            title="Cancellation & Refund Policy"
            description="this is Cancellation & Refund Policy page"
        >
            <Grid
                container
                spacing={0}
                sx={{
                    // backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    backgroundPosition: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid
                // sx={{
                //     background: "rgba( 255, 255, 255, 0.45 )",
                //     boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                //     backdropFilter: "blur( 10px )",
                //     height: "90%",
                //     width: "90%",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     borderRadius: "10px",
                //     marginY: "5%",
                // }}
                >
                    <Box
                        sx={{
                            margin: "2%",
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{ textAlign: "center", mb: 5, color: "#441F00" }}
                        >
                            CANCELLATION AND REFUND POLICY
                        </Typography>


                        <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                            a) Payment Policy
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ We accept online orders through COD or Prepaid. For Prepaid orders, we accept Visa, Master Card & American Express Debit and Credit Cards or through RazorPay. We also have payment wallets integrated.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ Please note for Visa and Master Card, you will be required to submit your 16 digit card number, card expiry date, and a 3 digit CVV Number (on the back of your card) when you make an online transaction using your Credit or Debit Card. For American Express cards, you will be required to submit your 15-digit card number and 4 digit code.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ If the amount has been deducted from your account, but you haven’t received a confirmation from us, you can reach out to our customer care team at mail id or on contact no and provide the following information:
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                            ● Transaction Ref Number
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                            ● Registered Email ID and Phone Number from which order was placed
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                            ● Date & Time of Transaction
                        </Typography>

                        <Box sx={{ margin: "2%" }}>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                                b) Shipping Policy
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ The shipping charges are mentioned at the time of checkout.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ Once your order has been confirmed, you will receive an email and SMS regarding order confirmation.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ Once your order is dispatched, you will receive the tracking number and tracking link.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ The estimated time of delivery may vary from state to state. Working days are Monday – Friday and we will try our best to furnish all orders within 4-7 working days.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ If you are ordering products during a sale event, delivery may be delayed.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ Partially Shipped orders are completely normal. Rest assured you will have to pay for the order in parts your received if opted for COD.
                            </Typography>
                        </Box>

                        <Box sx={{ margin: "2%" }}>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                                c) Delivery Policy
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ To ensure safety and hygiene of all our customers and our staff, there may be delays in delivery. We take extreme precaution on the safety of the products, while manufacturing, packing, and dispatching them.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ COD option is available for all orders subject to promotions and offers.
                            </Typography>
                            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                                ⮚ If the shipments are damaged or tampered with, please do not accept it. inform our customer care immediately.
                            </Typography>
                        </Box>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ If the order is marked as delivered and you haven’t received the order, please inform our customer care team within 24hrs. We request you to give us 3 working days to investigate and resolve your query.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ For all claims regarding shortage, damage, wrong product received, please inform our customer care team within 24hrs of receiving the order and we will resolve your query in 2-3 working days.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ We are not responsible for damages and misplacement post-delivery.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ We reserve the right to pause deliveries to any part of the country at any time and will inform you of the same. We advise you to enter your Pin Code correctly at the time of placing the order.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                            d) Cancellation Policy
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ Orders once ‘fulfilled’ or dispatched are not eligible for cancellation.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ If you wish to cancel your order before it is ‘fulfilled’, please log in to your account to check the status of the same and action cancellation if available. You can also contact our customer care if you have difficulty with the same.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                            e) Return and Exchange Policy
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ Our Products are non-returnable and non-exchangeable due to hygiene reasons.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ In case you would want a refund / replacement of your product, please contact our customer care within 24hrs of receiving the order. The agent may ask for subsequent proof such as images of the product, packaging, and invoice. We request you to provide us 3 working days to investigate the matter.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ In case you have placed a request for exchange/return of undamaged product(s) and we accept your request, the delivery charges have to be borne by you.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ You will be eligible for a full refund or replacement without incurring any charges if:
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Products are missing from your order
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Products are Damaged / Leaking
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Wrong Product Received
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Expired Product Received
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ Replacement / Refund will not be processed if:
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● The product is tampered with.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Invoice copy is missing.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Batch Number, Manufacturing Date, MRP is tampered with.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● If it’s without original packaging.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● If the query is not raised within 24hrs of receiving the product.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                            ● Allergic reactions due to product usage – we recommend you do a small patch test before usage and go through the ingredient list on the product page in detail before placing an order.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                            f) Refund Policy
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ We may ask for some proof before initiating a refund.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ Prepaid orders will take 5-7 business days for the refund to reflect in your account. You will receive a SMS and Email when the refund has been initiated and you may follow the link on the email to track your refund status.
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                            ⮚ COD orders will be refunded via bank transfers and may take 15 business days to reflect in your account.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </PageContainer>
    );
}
