import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import img1 from "../../assets/images/backgrounds/login_bg.jpg";
import LogoIcon from "../../layouts/full-layout/logo/LogoIcon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function Terms() {
  const navigate = useNavigate();

  return (
    <PageContainer title="TERMS OF USE" description="this is TERMS OF USE page">
      <Grid
        container
        spacing={0}
        sx={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          backgroundPosition: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            // background: "rgba( 255, 255, 255, 0.45 )",
            // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            // backdropFilter: "blur( 10px )",
            // height: "90%",
            // width: "90%",
            // justifyContent: "center",
            // alignItems: "center",
            // borderRadius: "10px",
            // marginY: "5%",
          }}
        >
          <Box
            sx={{
              margin: "2%",
            }}
          >
            <Typography
              variant="h1"
              sx={{ textAlign: "center", mb: 5, color: "#441F00" }}
            >
              TERMS OF USE
            </Typography>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                1. GENERAL
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                a) This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                b) This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of INARA SKIN.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                c) The domain name INARA SKIN ("Mobile Application"), including its online services, such as use of software on and information, poems, articles, news, audios, videos, blogs and text, graphics, images and information obtained from service providers and any other material contained on the is owned and operated by Stylin Advisory Services Private Limited (“Company”) a Private Company limited by shares, incorporated under the provisions of the Companies Act, 2013 having its registered office at Ground Floor, J- 1/37, DLF City Phase 2, Gurugram, Haryana- 122008 where such expression shall, unless repugnant to the context thereof, officers, deemed to include its respective representatives, administrators and officers.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                d) For the purpose of these Terms of Use (“Terms”), wherever the context so requires:
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
                i) The term ‘You’ or “User” shall mean any legal person or entity accessing using this to services provided or registered on the Mobile Application, who is competent to enter into binding contracts, as per the provisions of the Indian Contract Act, 1872.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
                ii) The terms ‘We’, ‘Us’ &‘Our’ shall mean the mobile app or Company as the context so requires.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                iii) The terms ‘Party’& ‘Parties’ shall respectively be used to refer to the User and the Company individually and collectively, as the context so requires.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                e) The headings of each section in these Terms are only for the purpose of organizing the various provisions under these Terms in an orderly manner, and shall not be used by either Party to interpret the provisions contained herein in any manner. Further, it is specifically agreed to by the Parties that the headings shall have no legal or contractual value.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                f) The use of the mobile application by the user is solely governed by these Terms as well as the Privacy Policy (“Policy”), available on the mobile app and any modifications or amendments made thereto by the Company from time to time, at its sole discretion. Visiting the home page of the app and/or using any of the services provided on the shall be deemed to signify the User’s unequivocal acceptance of these Terms and the aforementioned Policy, and the User expressly agrees to be bound by the same.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                g) The User unequivocally agrees that these Terms and the aforementioned Policy constitute a legally binding agreement between the User and the Company, and that the User shall be subject to the rules, guidelines, policies, terms, and conditions applicable to any service that is provided by the mobile application/, and that the same shall be deemed to be incorporated into these Terms, and shall be treated as part and parcel of the same.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                h) The Company reserves the sole and exclusive right to amend or modify these Terms without any prior permission or intimation to the User, and the User expressly agrees that any such amendments or modifications shall come into effect immediately. The User has a duty to periodically check the terms and stay updated on its requirements. In so far as the User complies with these Terms, he/she is granted a personal, non-exclusive, non-transferable, revocable, limited privilege to enter and use the application.
              </Typography>
            </Box>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                2. ABOUT INARA SKIN
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                Introducing Inara Skin, where nature meets science to bring you the best in skincare. At Inara Skin, we believe that true beauty starts with healthy skin. Our mission is to provide high-quality, effective skincare products that harness the power of natural ingredients to nourish, rejuvenate, and protect your skin.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                At Inara Skin, we are committed to:
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
                ● Quality: We use only the finest natural ingredients, carefully selected for their proven benefits. Our formulations are designed to be gentle yet effective, ensuring that your skin receives the care it deserves.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ● Innovation: Combining the best of nature and science, our products are developed using advanced skincare technology to maximize their effectiveness. We continuously strive to improve our formulations and stay at the forefront of skincare innovation.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 1 }}>
                ● Transparency: We believe in being honest and transparent with our customers. From our ingredient lists to our manufacturing processes, we provide complete transparency so you can make informed choices about your skincare routine.
              </Typography>

              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ● Sustainability: We are dedicated to protecting our planet. Our products are cruelty-free, and we use eco-friendly packaging whenever possible. We are committed to reducing our environmental footprint and promoting sustainability in all aspects of our business.
              </Typography>
              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  3. OUR SERVICES
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  At Inara Skin, we are dedicated to providing exceptional skincare solutions and personalized experiences to help you achieve your best skin ever.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  a) Personalized Skincare Consultations: We believe that every skin type is unique, and so are its needs. Our personalized skincare consultations are designed to help you understand your skin better and find the perfect products for your skincare routine. Our expert skincare consultants will assess your skin type, concerns, and goals to recommend a customized regimen that delivers optimal results.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  b) Customized Skincare Regimens: Navigating the world of skincare can be overwhelming, but we’re here to make it easy for you. Based on your consultation, we will create a tailored skincare regimen that addresses your specific concerns, whether it’s hydration, anti-aging, brightening, or acne control. Our goal is to provide you with a simple yet effective routine that fits seamlessly into your lifestyle.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  c) Exclusive Product Recommendations: With so many products available, finding the right one can be a challenge. Our experts will guide you through our extensive product range and recommend the best options suited to your skin type and concerns. We take pride in offering high-quality, natural, and scientifically-backed skincare products that deliver visible results.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  d) Skin Analysis and Treatment Plans: Understanding your skin’s current condition is key to improving its health and appearance. Our advanced skin analysis services use state-of-the-art technology to evaluate your skin’s texture, tone, hydration levels, and more. Based on this analysis, we develop targeted treatment plans that address underlying issues and enhance your skin’s natural beauty.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  e) Educational Resources and Workshops: At Inara Skin, we believe in empowering our customers with knowledge. Our educational resources, including articles, videos, and workshops, are designed to help you make informed decisions about your skincare routine. Learn about the benefits of natural ingredients, skincare best practices, and the latest trends in the industry from our team of experts.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  f) Exclusive Membership Programs: Join our Inara Skin family by becoming a member of our exclusive membership programs. Enjoy benefits such as early access to new products, special discounts, personalized skincare advice, and invitations to members-only events. Our membership programs are designed to provide you with continuous support and rewards as you journey towards healthier skin.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  g) Customer Support and Care: Your satisfaction is our top priority. Our dedicated customer support team is always here to assist you with any questions, concerns, or feedback you may have. Whether you need help with product selection, order inquiries, or skincare advice, we are committed to providing you with exceptional service every step of the way.
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  h) Experience the Inara Skin Difference: At Inara Skin, we are passionate about helping you achieve beautiful, healthy skin. Our services are designed to offer you personalized care, expert advice, and high-quality products that make a real difference. Discover the transformative power of our skincare solutions and experience the Inara Skin difference today.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  4. ELIGIBILITY
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  The User represents and warrants that he/she is competent and eligible to enter into legally binding agreements and that he/she has the requisite authority to bind himself/herself to these Terms, as determined solely by the provisions of the Indian Contract Act, 1872. The User may not use this Website if he/she is not competent to contract under the Indian Contract Act, 1872, or is disqualified from doing so by any other applicable law, rule, or regulation currently in force.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  5. USER ACCOUNT, PASSWORD, AND SECURITY
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  Upon completing the Mobile Application's registration process, you will receive a password and account designation. You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under them. You agree to:
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  a) Immediately notify Stylin Advisory Services Private Limited of any unauthorized use of your password or account or any other breach of security and
                </Typography>

              </Box>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                b) Ensure that you exit from your account at the end of each session. Stylin Advisory Services Private Limited cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.
              </Typography>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  6. PRODUCT DESCRIPTIONS
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  In describing our business and products on this website, we attempt to be as accurate as possible. However, we do not warrant that the content of this site is accurate, complete, reliable, current, or error-free. We reserve the right to correct any typographical errors, inaccuracies, or omissions from time to time without prior notice.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  7. TERMINATION
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  The Company reserves the right, in its sole discretion, to unilaterally terminate the User’s access to the services offered on the app, or any portion thereof, at any time, without notice or cause. The User shall continue to be bound by these Terms, and it is expressly agreed to by the Parties that the User shall not have the right to terminate these Terms till the expiry of usage of the app.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  8. ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or timelier sources of information. Any reliance on the material on this site is at your own risk.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  9. MODIFICATIONS TO THE SERVICE AND PRICES
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● Prices for our products are subject to change without notice.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  10. PRODUCTS OR SERVICES (if applicable)
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  ● We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  11. ACCURACY OF BILLING AND ACCOUNT INFORMATION
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  12. PAYMENT AND CANCELLATION POLICY
                </Typography>

                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  a) Payment Policy
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ We accept online orders through COD or Prepaid. For Prepaid orders, we accept Visa, Master Card & American Express Debit and Credit Cards or through RazorPay. We also have payment wallets integrated.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ Please note for Visa and Master Card, you will be required to submit your 16 digit card number, card expiry date, and a 3 digit CVV Number (on the back of your card) when you make an online transaction using your Credit or Debit Card. For American Express cards, you will be required to submit your 15-digit card number and 4 digit code.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ If the amount has been deducted from your account, but you haven’t received a confirmation from us, you can reach out to our customer care team at mail id or on contact no and provide the following information:
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                  ● Transaction Ref Number
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                  ● Registered Email ID and Phone Number from which order was placed
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
                  ● Date & Time of Transaction
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  b) Shipping Policy
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ The shipping charges are mentioned at the time of checkout.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ Once your order has been confirmed, you will receive an email and SMS regarding order confirmation.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ Once your order is dispatched, you will receive the tracking number and tracking link.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ The estimated time of delivery may vary from state to state. Working days are Monday – Friday and we will try our best to furnish all orders within 4-7 working days.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ If you are ordering products during a sale event, delivery may be delayed.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ Partially Shipped orders are completely normal. Rest assured you will have to pay for the order in parts your received if opted for COD.
                </Typography>
              </Box>

              <Box sx={{ margin: "2%" }}>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                  c) Delivery Policy
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ To ensure safety and hygiene of all our customers and our staff, there may be delays in delivery. We take extreme precaution on the safety of the products, while manufacturing, packing, and dispatching them.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ COD option is available for all orders subject to promotions and offers.
                </Typography>
                <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                  ⮚ If the shipments are damaged or tampered with, please do not accept it. inform our customer care immediately.
                </Typography>
              </Box>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ If the order is marked as delivered and you haven’t received the order, please inform our customer care team within 24hrs. We request you to give us 3 working days to investigate and resolve your query.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ For all claims regarding shortage, damage, wrong product received, please inform our customer care team within 24hrs of receiving the order and we will resolve your query in 2-3 working days.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ We are not responsible for damages and misplacement post-delivery.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ We reserve the right to pause deliveries to any part of the country at any time and will inform you of the same. We advise you to enter your Pin Code correctly at the time of placing the order.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                d) Cancellation Policy
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ Orders once ‘fulfilled’ or dispatched are not eligible for cancellation.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ If you wish to cancel your order before it is ‘fulfilled’, please log in to your account to check the status of the same and action cancellation if available. You can also contact our customer care if you have difficulty with the same.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                e) Return and Exchange Policy
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ Our Products are non-returnable and non-exchangeable due to hygiene reasons.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ In case you would want a refund / replacement of your product, please contact our customer care within 24hrs of receiving the order. The agent may ask for subsequent proof such as images of the product, packaging, and invoice. We request you to provide us 3 working days to investigate the matter.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ In case you have placed a request for exchange/return of undamaged product(s) and we accept your request, the delivery charges have to be borne by you.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ You will be eligible for a full refund or replacement without incurring any charges if:
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Products are missing from your order
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Products are Damaged / Leaking
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Wrong Product Received
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Expired Product Received
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ Replacement / Refund will not be processed if:
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● The product is tampered with.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Invoice copy is missing.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Batch Number, Manufacturing Date, MRP is tampered with.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● If it’s without original packaging.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● If the query is not raised within 24hrs of receiving the product.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ● Allergic reactions due to product usage – we recommend you do a small patch test before usage and go through the ingredient list on the product page in detail before placing an order.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", mb: 2 }}>
                f) Refund Policy
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ We may ask for some proof before initiating a refund.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ Prepaid orders will take 5-7 business days for the refund to reflect in your account. You will receive a SMS and Email when the refund has been initiated and you may follow the link on the email to track your refund status.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                ⮚ COD orders will be refunded via bank transfers and may take 15 business days to reflect in your account.
              </Typography>
              <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
                13. TERMS, CONDITIONS, AND DUTIES OF THE USER
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                a. It is the duty of User to provide one’s true/accurate/complete information, and all the details relating thereof. If any User detail is found to be false, then the Company shall reserve the right on its sole discretion to reject the registration and debar the User from using the Services available, without prior intimation whatsoever.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                b. The User agrees to comply with all notices or instructions given by the Company from time to time to enable the use of the Services.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                c. User shall indemnify and hold the Company, harmless for any loss or damage arising out of User’s failure to comply with any applicable laws or regulations and for breach of the following warranties and representations.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                d. The User shall at all times during the pendency of this agreement endeavor to protect and promote the interest of the Company and ensure that there will be no damage to third party due to act/omission on the part of the User.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                e. User is bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or software obtained from the app. Any such use/limited use of the app will only be allowed with the prior express written permission of the Company.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                f. The user will be responsible for maintaining the confidentiality of the account and password and for restricting access to your computer to prevent unauthorized access to the account.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
                g. The User further undertakes not to:
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                i. Engage in any activity that interferes with or disrupts access to the mobile app/or the services provided therein (or the servers and networks which are connected to the app).
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                ii. Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                iii. Post any image/file/data that infringes the copyright, patent or trademark of another person or legal entity.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                iv. Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the mobile application.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                v. Download any file posted/uploaded by another User of the app that the User is aware, or should reasonably be aware, cannot be legally distributed in such a manner.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                vi. Probe, scan or test the vulnerability of the app or any network connected to the mobile application, nor breach the security or authentication measures on the app or any network connected to it.
              </Typography>
              <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
                vii. Disrupt or interfere with the security of, or otherwise cause harm to, the mobile app, systems resources, accounts, passwords, servers or networks connected to or accessible through the mobile app or any affiliated or linked Mobiles.
              </Typography>




            </Box>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              viii. Use the Inara Skin or any material or content therein for any purpose that is unlawful or prohibited by these Terms, or to solicit the performance of any illegal activity or other activity which infringes the rights of this app or any other third party/ies;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              ix. Violate any code of conduct or guideline which may be applicable for or to any particular service offered on the Inara Skin;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              x. Violate any applicable laws, rules or regulations currently in force within or outside India;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              xi. Violate any portion of these Terms or the Policy, including but not limited to any applicable additional terms of the Inara Skin contained herein or elsewhere, whether made by amendment, modification, or otherwise;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              xii. Threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or cause incitement to the commission of any cognizable offence, or prevent the investigation of any offence, or insult any other nation.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              xiii. Publish, post, or disseminate information that is false, inaccurate or misleading;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              xiv. Commit any act that causes the Company to lose (in whole or in part) the services of its internet service provider ("ISP") or in any manner disrupts the services of any other supplier/service provider of the Company;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
              xv. The User expressly agrees and acknowledges that the Company has no obligation to monitor the materials posted on the app, but that it has the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              14. SUSPENSION OF USER ACCESS AND ACTIVITY
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Notwithstanding other legal remedies that may be available to it, the Company may in its sole discretion limit the User’s access and/or activity by immediately removing the User’s access credentials either temporarily or indefinitely, or suspend/terminate the User’s membership, and/or refuse to provide User with access to the app, without being required to provide the User with notice or cause:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              a) If the User is in breach of any of these Terms or the Policy;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              b) If the User has provided wrong, inaccurate, incomplete or incorrect information;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              c) If the User’s actions may cause any harm, damage or loss to the other Users or to the Company, at the sole discretion of the Company;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
              d) If the User’s action involves copying or duplicating in any manner any content or other information available from the mobile app.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              15. INDEMNITY AND LIMITATIONS
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              The User hereby expressly agrees to defend, indemnify and hold harmless the Company, its officials, affiliate Users, employees, against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon the User's actions or inactions, including but not limited to any warranties, representations or undertakings, or in relation to the non-fulfilment of any of the User’s obligations under this Agreement, or arising out of the User's infringement of any applicable laws, rules and regulations, including but not limited to infringement of intellectual property rights, payment of statutory dues and taxes, claims of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers, or the infringement of any other rights of a third party.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              16. INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Unless expressly agreed to in writing, nothing contained herein shall give the User a right to use any of the Company’s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports, images, videos and other distinctive features, save according to the provisions of these Terms.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by the app and other distinctive brand features of the app are the property of the Company. Furthermore, with respect to the mobile app created by the Company, the Company shall be the exclusive owner of all the designs, graphics and the like, related to the Mobile Application.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              The User may not use any of the intellectual property displayed on the mobile app in any manner that is likely to cause confusion among existing or prospective Users of the app, or that in any manner disparages or discredits the Company, to be determined in the sole discretion of the Company.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              The User is further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by the User will result in legal action being initiated against the User by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by the Parties that the contents of this clause shall survive even after the termination or expiry of the Terms and/or Policy.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              17. DISCLAIMER OF WARRANTIES AND LIABILITIES
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              a) Except as otherwise expressly stated on the mobile app, all services offered on the app are offered on an "as is" basis without any warranty whatsoever, either express or implied.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              b) Information accessed through the Company and other touch points is presented in summary form and is not exhaustive. The Company makes no warranties or representations as to its accuracy or completeness. This information is provided "as is" without warranty of any kind, either express or implied.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              c) The Company does not guarantee that the functions and services contained in the app will be uninterrupted or error-free, or that the mobile app or its server will be free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks involved with the User’s use of the app.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              d) It is further agreed to by the Parties that the contents of this Clause shall survive even after the termination or expiry of the Terms and/or Policy.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              18. SUBMISSIONS
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Any comments, feedback, ideas, suggestions, initiation, or any other content contributed by the User to the Company or the app will be deemed to include a royalty-free, perpetual, irrevocable, nonexclusive right and license for the Company to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create derivative works, display worldwide, or act on such content, without additional approval or consideration, in any media, or technology now known or later developed, for the full term of any rights that may exist in such content, and the User hereby waives any claim to the contrary.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              The User hereby represents and warrants that he/she owns or otherwise controls all of the rights to the content contributed to the mobile app, and that use of such content by the Company/mobile application does not infringe upon or violate the rights of any third party. In the event of any action initiated against the Company/mobile application by any such affected third party, the User hereby expressly agrees to indemnify and hold harmless the Company, for its use of any such information provided to it by the User.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              19. DISPUTE RESOLUTION AND JURISDICTION
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              It is expressly agreed to by the Parties hereto that the formation, interpretation and performance of these Terms and any disputes/claim/refund/compensation arising herefrom will be resolved through a two-step Alternate Dispute Resolution (“ADR”) mechanism. It is further agreed to by the Parties that the contents of this Clause shall survive even after the termination or expiry of the Terms and/or Policy.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              a) Mediation: In case of any dispute/claim/compensation between the parties, the Parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of both Parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one Party communicating the existence of a dispute to the other Party, the dispute will be resolved by arbitration, as detailed herein below;
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 1 }}>
              b) Arbitration: In the event that the Parties are unable to amicably resolve a dispute by mediation, said dispute will be referred to arbitration under Arbitration and Conciliation Act, 1996 by a sole arbitrator to be appointed by the Company, and the award passed by such sole arbitrator will be valid and binding on both Parties. The Parties shall bear their own costs for the proceedings, although the sole arbitrator may, in his/her sole discretion, direct either Party to bear the entire cost of the proceedings. The seat of Arbitration shall be in Gurugram, India.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 4, mb: 2 }}>
              c) This Agreement shall be governed by and construed in accordance with the laws of India. The Parties hereby agree to submit to the jurisdiction of the competent Courts at Gurugram.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              20. NOTICES
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Any or all kinds of communication relating to any dispute or grievance experienced by the User may be communicated to the Company by the User reducing the same to writing, and sending the same to the registered office of the Company by Registered Post Acknowledgement Due / Speed Post Acknowledgement Due (RPAD / SPAD).
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              21. MISCELLANEOUS PROVISIONS
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              a) Entire Agreement: These Terms, read with the Policy, form the complete and final contract between the User and the Company with respect to the subject matter hereof and supersedes all other communications, representations and agreements (whether oral, written or otherwise) relating thereto.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              b) Waiver: The failure of either Party at any time to require performance of any provision of these Terms shall in no manner affect such Party's right at a later time to enforce the same. No waiver by either Party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms.
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              c) Severability: If any provision/clause of these Terms is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality and enforceability of the remaining provisions/clauses of these Terms shall in no way be affected or impaired thereby, and each such provision/clause of these Terms shall be valid and enforceable to the fullest extent permitted by law.
            </Typography>

            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              22. CONTACTING US
            </Typography>
            <Typography variant="p" sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}>
              Stylin Advisory Services Private Limited's collection, use, and disclosure of personal information are under this Privacy Policy. If you have any questions about this Privacy Policy, please contact us by sending an email to the e-mail address provided, or please write to the following address:
            </Typography>
            <Typography variant="p" sx={{ textAlign: "center", display: "block", ml: 4, mb: 2, fontWeight: "bold" }}>
              Ground Floor, J- 1/37, DLF City Phase 2, Gurugram, Haryana- 122008
            </Typography>


          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
