import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full-layout/loadable/Loadable";
import Privacy from "../views/privacy";
import Terms from "../views/terms";
import Disclaimer from "../views/disclaimer";
import CancellationRefundPolicy from "../views/cancellationRefund";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import("../layouts/full-layout/FullLayout"))
);
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank-layout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("../views/authentication/ResetPassword"))
);
const CheckAgent = Loadable(lazy(() => import("../views/checkAgent")));

/* ****Pages***** */
const Home = Loadable(lazy(() => import("../views/Home")));
const Cart = Loadable(lazy(() => import("../views/cart")));
const Dashboard = Loadable(lazy(() => import("../views/dashboard")));
const Day = Loadable(lazy(() => import("../views/routine/day")));
const Night = Loadable(lazy(() => import("../views/routine/night")));
/* ****Routes***** */

const Router = [
  {
    path: "/check",
    element: <CheckAgent />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
  {
    path: "/term-of-use",
    element: <Terms />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
  {
    path: "/cancellation-refund-policy",
    element: <CancellationRefundPolicy />,
  },
  {
    path: "/",
    element: <FullLayout />, //checking Authentication
    children: [
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/cart", exact: true, element: <Cart /> },
      {
        path: "/day",
        exact: true,
        element: <Day />,
      },
      {
        path: "/night",
        exact: true,
        element: <Night />,
      },
    ],
  },
  {
    path: "auth",
    element: <BlankLayout />,
    children: [
      { path: "404", exact: true, element: <Error /> },
      { path: "login", exact: true, element: <Login /> },
      { path: "reset-password", exact: true, element: <ResetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
