import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import img1 from "../../assets/images/backgrounds/home_bg.jpg";
import LogoIcon from "../../layouts/full-layout/logo/LogoIcon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function Disclaimer() {
    const navigate = useNavigate();
    return (
        <PageContainer
            title="Privacy Policy"
            description="this is Privacy policy page"
        >
            <Grid
                container
                spacing={0}
                sx={{
                    // backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    backgroundPosition: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid
                // sx={{
                //     background: "rgba( 255, 255, 255, 0.45 )",
                //     boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                //     backdropFilter: "blur( 10px )",
                //     height: "90%",
                //     width: "90%",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     borderRadius: "10px",
                //     marginY: "5%",
                // }}
                >
                    <Box
                        sx={{
                            margin: "2%",
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{ textAlign: "center", mb: 5, color: "#441F00" }}
                        >
                            DISCLAIMER
                        </Typography>

                        <Box>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                Please read these Terms of Use & Privacy Policy ("Agreements")
                                carefully before using our services or the mobile application
                                Inara Skin ("us," "we," "our," or "the mobile application"),
                                owned and operated by Tagbin Services Pvt. Ltd ("Company").
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                Tagbin Services Pvt. Ltd is a Private Company limited by shares,
                                incorporated under the Companies Act, 2013, with its registered
                                office at 3rd Floor, Wing A, Enkay Centre, Vaniya Kunj,
                                UdyogVihar, Phase V, UdyogVihar, Sector 19, Gurugram, Haryana –
                                122016.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                These Agreements establish the legally binding terms and
                                conditions for your use of the service at{" "}
                                <a
                                    href="https://inaraskinapp.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#441F00", textDecoration: "underline" }}
                                >
                                    www.Inaraskin.com
                                </a>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                1. Acceptance of Terms
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                Your use of this mobile app, including as a prospective or
                                registered user, is solely governed by this policy and any
                                referenced policies. By navigating past the homepage or using
                                any of the services, you acknowledge that you have read and
                                agreed to all the agreements or policies binding upon you and
                                that you are contracting and undertaking binding obligations
                                with the Company. You will be subject to the rules, guidelines,
                                policies, terms, and conditions applicable to any service
                                provided by this mobile application, which shall be incorporated
                                into these Terms of Use & Privacy Policy and considered part and
                                parcel of these Agreements.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                2. Service and Content Disclaimer
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                Inara Skin provides product recommendations, including
                                personalized morning (AM) and evening (PM) skincare routines
                                with detailed application instructions, based on the information
                                you provide. Along with that each user will have a dermatologist
                                assigned to their profile, who will provide personalized
                                skincare logic and recommendations. While we aim to offer
                                helpful and accurate recommendations, we do not guarantee the
                                efficacy of any product or routine. Our content and services are
                                intended for educational purposes and should not replace
                                professional medical advice.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                3. Modification of Agreements
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The Company holds the sole right to modify these Agreements
                                without prior permission from or informing you. It is your
                                responsibility to periodically check the terms and stay updated
                                on their requirements. Continuing to use the mobile application
                                following any changes constitutes your consent to the amended
                                Agreements. As long as you comply with these Agreements, we
                                grant you a personal, non-exclusive, non-transferable, limited
                                privilege to enter and use the mobile application.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                4. Limitation of Liability
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The Company, its owners, managers, directors, employees, agents,
                                advertisers, or affiliates will not be liable for any loss,
                                damage, injury, non-delivery, defective delivery, delay,
                                inaccessibility, failure, interruption, alteration, or damage of
                                any data, or in terms of money or health, third-party
                                information, or other information caused to you arising from the
                                use of the mobile application, its online services, or
                                materials, including legal actions arising from negligence or
                                other third-party actions due to misconduct or omissions/acts of
                                other users on the mobile application, such as registered users
                                or partners. By implicitly or explicitly accepting these Terms
                                of Service, you also accept and agree to be bound by other
                                Company Policies, including the Privacy Policy, which may be
                                amended from time to time.{" "}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                5. Medical Disclaimer
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                Disclaimer
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The content and information provided on this website, the blog,
                                videos, pamphlets, social media, or other materials
                                (collectively referred to as "Our Sites") are provided by Inara
                                Skin for educational purposes only.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                While many of the natural ingredients in our products are
                                believed to have healing properties, the information should
                                never be used to self-medicate or treat any form of physical or
                                mental disease or health problem. We do not recommend the use of
                                these products as a substitute for medical care.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The statements regarding health-related benefits of certain
                                ingredients have not been evaluated by the Food and Drug
                                Administration and are not intended to be construed as medical
                                advice to diagnose, treat, cure, or prevent any disease or
                                health condition. Never disregard professional medical advice or
                                delay seeking medical advice because of something you have read
                                on "Our Sites." If you have or suspect that you have a medical
                                condition, please contact your healthcare provider immediately.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                This notice is required by the Federal Food, Drug, and Cosmetic
                                Act.
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                6. Safety and Allergy Guidelines
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The following safety guidelines should be observed before using
                                Inara Skin products:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ If you have any allergies, please read the product label
                                carefully. We recommend that people with sensitive skin perform
                                a patch test on the nape of the neck first to ensure there is no
                                reaction. Discontinue use immediately if irritation occurs.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ If you are uncertain about possible sensitivity, always do a
                                patch test before use or consult your healthcare professional.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ Products and advice by Inara Skin make no claim to cure or
                                prevent any disease or medical problem and are not intended to
                                substitute other therapy or medical advice.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ Inara Skin is not responsible for any individual reaction to
                                any particular ingredient. Each product description on our
                                website includes a complete list of ingredients. People with
                                sensitivities to any listed ingredient should not use the
                                product. If you are in doubt or have sensitive skin, always try
                                an allergy patch test, and if at any time irritation occurs,
                                discontinue use of the product.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ All product reviews reflect the personal views of our
                                customers and do not imply or represent any claims by Inara
                                Skin. Individual results may vary.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", ml: 2, mb: 2 }}
                            >
                                ⮚ In the case of pregnancy, please consult with your healthcare
                                professional.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                7. Legal Disclaimer
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                The information on this website has been compiled from published
                                sources and is provided only as a guide. Although every effort
                                has been made to ensure that the information published on this
                                site is correct and up to date, Inara Skin cannot guarantee the
                                accuracy of all information presented and accepts no liability
                                in respect of any omission or error. Customers should always
                                satisfy themselves as to the ultimate suitability of a product
                                for an intended application. Inara Skin accepts no liability in
                                the unlikely event of an adverse reaction to any ingredient
                                occurring when using one of its products.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                8. Governing Law
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                These Agreements shall be governed by and construed in
                                accordance with the laws of the Republic of India. Any dispute
                                arising out of or relating to these terms shall be exclusively
                                resolved in the courts located in Gurugram, India.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ textAlign: "left", mb: 2, fontWeight: "bold" }}
                            >
                                9. Contact Information
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ textAlign: "left", display: "block", mb: 2 }}
                            >
                                If you are not satisfied with the resolution of your query or
                                complaint, you have the option to discontinue use of the mobile
                                application or cancel your account with www.inaraskin.com.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </PageContainer>
    );
}
