import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logos/logo-dark.svg";

const LogoIcon = () => {
  return (
    <Link underline="none" to="/">
      <img src={Logo} style={{ width: "100px" }} alt="LOGO" />
    </Link>
  );
};

export default LogoIcon;
