import {
  GET_RECOMMENDATION_PRODUCT_FAILED,
  GET_RECOMMENDATION_PRODUCT_REQUEST,
  GET_RECOMMENDATION_PRODUCT_SUCCESS,
} from "../constants";

const INIT_STATE = {
  isLoaded: false,
  isLoading: false,
  recommendationProduct: {},
};

const RecommendationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECOMMENDATION_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_RECOMMENDATION_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        recommendationProduct: action.value,
      };
    case GET_RECOMMENDATION_PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default RecommendationReducer;
